<template>
    <div>
      <b-overlay :show="show" rounded="sm" no-fade>
        <b-card-code title="Informasi Data Legal">
  
          <b-row class="mt-2 mb-2" align="end">
            <b-col align="start" md="4">
              <v-select
                v-model="kavling_id"
                :options="kavlingOptions"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :reduce="(_id) => _id._id"
                placeholder="Pilih Kavling"
                @input="changeStatus()"
              />
            </b-col>

            <b-col align="start" md="4">
              <v-select
                v-model="submission_status"
                :options="submissionStatusOptions"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                placeholder="Pilih Status Pengajuan Notaris"
                @input="changeStatus()"
              />
            </b-col>
  
            <b-col md="1"> </b-col>
            <!-- spasi -->
            <b-col align="end" md="3">
              <!-- <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="showModalTambah()"
              >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="align-middle">Tambah</span>
              </b-button> -->
            </b-col>
          </b-row>
  
          <b-row class="mt-2 mb-2" align="end">
  
            <b-col md="2">
              <b-form-input
                id="name"
                v-model="block_name"
                placeholder="Cari Nama Blok"
                @input="changeStatus()"
              />
            </b-col>

            <b-col md="3">
              <b-form-input
                id="name"
                v-model="customer_name"
                placeholder="Cari Nama Customer"
                @input="changeStatus()"
              />
            </b-col>

            <b-col md="3">
              <v-select
                v-model="legality_payment_status"
                :options="legalPaymentStatusOptions"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                placeholder="Pilih Status Pembayaran"
                @input="changeStatus()"
              />
            </b-col>
  
            <b-col md="2" align="start">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                @click="resetFilter()"
              >
                Reset
              </b-button>
            </b-col>
  
          </b-row>
  
          <!-- table -->
          <vue-good-table
            mode="remote"
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :search-options="{
              enabled: false,
              externalQuery: searchTerm,
            }"
            :pagination-options="{
              enabled: true,
            }"
            @on-sort-change="onSortChange"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'created_date'">
                {{ props.row.created_date.substring(0, 10) }}
              </span>

              <span v-else-if="props.column.field === 'submission_status'">
                <span v-if="props.row.submission_status === 'PENGAJUAN SELESAI' ">
                  <b-badge variant="success"> PENGAJUAN SELESAI </b-badge>
                </span>
  
                <span v-if="props.row.submission_status === 'PROSES REVISI' ">
                  <b-badge variant="warning"> PROSES REVISI </b-badge>
                </span>
  
                <span v-if="props.row.submission_status === 'BELUM DIAJUKAN' ">
                  <b-badge variant="info"> BELUM DIAJUKAN </b-badge>
                </span>
  
                <span v-if="props.row.submission_status === 'PROSES PENGAJUAN' ">
                  <b-badge variant="primary"> PROSES PENGAJUAN </b-badge>
                </span>
              </span>

              <span v-else-if="props.column.field === 'legality_payment_status'">
                <span v-if="props.row.legality_payment_status === 'SUDAH LUNAS' ">
                  <b-badge variant="success"> SUDAH LUNAS </b-badge>
                </span>
  
                <span v-if="props.row.legality_payment_status === 'BELUM BAYAR' ">
                  <b-badge variant="warning"> BELUM BAYAR </b-badge>
                </span>
  
                <span v-if="props.row.legality_payment_status === 'DP' ">
                  <b-badge variant="primary"> DP </b-badge>
                </span>
  
              </span>
  
              <span v-else-if="props.column.field === 'create_time'">
                {{ props.row.create_time.substring(0, 10) }}
              </span>
  
              <span v-else-if="props.column.field === 'length'">
                {{ props.row.length }} x {{ props.row.width }}
              </span>
  
              <span v-else-if="props.column.field === 'lineNumber'">
                {{ paging.page * 10 + (props.index + 1) }}.
              </span>
  
              <span v-else-if="props.column.field === 'price'">
               Rp. {{ props.row.price.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.") }}
              </span>
  
              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
  
                  <!-- <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    v-b-tooltip.hover.v-primary
                    title="Detail"
                    class="btn-icon mr-2"
                    @click="showModalDetail(props)"
                  >
                    <feather-icon icon="SearchIcon" class="cursor-pointer" />
                  </b-button> -->
  
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="warning"
                    v-b-tooltip.hover.v-warning
                    title="Ubah"
                    class="btn-icon mr-2"
                    @click="showModalUbah(props)"
                  >
                    <feather-icon icon="EditIcon" class="cursor-pointer" />
                  </b-button>
                  
                  <!-- <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="danger"
                    v-b-tooltip.hover.v-danger
                    title="Hapus"
                    class="btn-icon"
                    @click="deleteInfo(props)"
                  >
                    <feather-icon icon="Trash2Icon" class="cursor-pointer" />
                  </b-button> -->
  
              </span>
  
              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
  
            <!-- pagination -->
            
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Showing 1 to </span>
                  <b-form-select
                    v-model="paging.size"
                    :options="paging.perPageOptions"
                    class="mx-1"
                    @input="handlePageChange"
                  />
                  <span class="text-nowrap">
                    of {{ paging.total_elements }} entries
                  </span>
                </div>
  
                <div>
                  <b-pagination
                    :value="paging.page + 1"
                    :total-rows="paging.total_elements"
                    :per-page="paging.size"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @change="handleChangePage"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
  
          <!-- modal form Form Legal-->
          <b-modal
            id="modal-detail-blok"
            ref="ref-form-data-legal"
            title="Form Data Legal"
            hide-footer="true"
            size="lg"
            no-close-on-backdrop
          >
            <validation-observer ref="simpleRules">
              <b-form>
                <b-row>

                  <b-col md="12">
                    <b-form-group label-for="name">
                      <template v-slot:label>
                        Status Pengajuan Notaris <span class="text-danger">*</span>
                      </template>
  
                      <validation-provider
                        #default="{ errors }"
                        name="Name"
                        rules="required"
                      >
                        <v-select
                          v-model="formSubmit.submission_status"
                          :options="submissionStatusOptions"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          placeholder="Pilih Status Pengajuan Notaris"
                        />
                      <small class="text-danger">{{ errors[0]?'Status Pengajuan Notaris Wajib Diisi':'' }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group label-for="name">
                      <template v-slot:label>
                        Status Pembayaran <span class="text-danger">*</span>
                      </template>
  
                      <validation-provider
                        #default="{ errors }"
                        name="Name"
                        rules="required"
                      >
                        <v-select
                          v-model="formSubmit.legality_payment_status"
                          :options="legalPaymentStatusOptions"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          placeholder="Pilih Status Pembayaran"
                        />
                      <small class="text-danger">{{ errors[0]?'Status Pembayaran Wajib Diisi':'' }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group label-for="name">
                      <template v-slot:label>
                        Fasilitas Customer <span class="text-danger">*</span>
                      </template>
  
                      <validation-provider
                        #default="{ errors }"
                        name="Name"
                        rules="required"
                      >
                        <v-select
                          v-model="formSubmit.customer_facility"
                          :options="customerFacilityOptions"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          placeholder="Pilih Fasilitas Customer"
                        />
                      <small class="text-danger">{{ errors[0]?'Fasilitas Customer Wajib Diisi':'' }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
  
                  <b-col md="12">
                    <b-form-group label="Keterangan" label-for="mc-country">
                      <b-form-textarea
                        rows="3"
                        v-model="formSubmit.description"
                        placeholder="Masukkan Keterangan"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
  
              </b-form>
  
              <b-row class="mt-2 mb-2"> 
                <b-col align="end"
                  >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="secondary"
                    @click="kembaliModalForm()"
                    class="mr-2"
                  >
                    Batal
                  </b-button>
                  
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="submitForm()"
                  >
                    Submit
                  </b-button>
                </b-col>
              </b-row>
            </validation-observer>  
          </b-modal>
          <!-- end modal form form kavling -->
  
        </b-card-code>
      </b-overlay>
    </div>
  </template>
  
  <script>
  import BCardCode from "@core/components/b-card-code/BCardCode.vue";
  import axios from "@axios";
  import vSelect from "vue-select";
  import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BPagination,
    BFormFile,
    BOverlay,
    BFormSelect,
    BBadge,
    BFormTextarea,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    BImg,
    BInputGroupPrepend,
    BCard,
    BCardText,
    BCardBody,
    BDropdownItem,
    BDropdown,
    VBTooltip,
  } from "bootstrap-vue";
  import Ripple from "vue-ripple-directive";
  import { VueGoodTable } from "vue-good-table";
  import store from "@/store/index";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import flatPickr from "vue-flatpickr-component";
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required, email } from '@validations'
  
  export default {
    components: {
      BCardCode,
      VBTooltip,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BFormCheckbox,
      BForm,
      BButton,
      BPagination,
      VueGoodTable,
      BFormFile,
      BOverlay,
      vSelect,
      BFormSelect,
      BBadge,
      BFormTextarea,
      BMedia,
      BAvatar,
      BMediaBody,
      BMediaAside,
      BAvatarGroup,
      BImg,
      BInputGroupPrepend,
      BCard,
      BCardBody,
      BCardText,
      BDropdownItem,
      BDropdown,
      flatPickr,
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      "b-tooltip": VBTooltip,
      Ripple,
    },
    data() {
      return {
        //here
        file: null,
  
        required,
        email,
  
        log: [],
        dir: false,
        pages: ["5", "10", "20", "30"],
  
        paging: {
          page: 0,
          total_pages: 0,
          size: 10,
          total_elements: 0,
          perPageOptions: [10, 25, 50, 100],
        },
  
        columns: [
          {
            label: "No",
            field: "lineNumber",
            thClass: "text-center",
            tdClass: "text-center",
            width: "70px",
          },
          {
            label: "Nama Kavling",
            field: "kavling_name",
            thClass: "text-center",
          },
          {
            label: "Nama Blok",
            field: "block_name",
            thClass: "text-center",
          },
          {
            label: "No. HP",
            field: "customer_phone",
            thClass: "text-center",
          },
          {
            label: "Nama Customer",
            field: "customer_name",
            thClass: "text-center",
          },
          {
            label: "Status Pengajuan Notaris",
            field: "submission_status",
            thClass: "text-center",
          }, 
          {
            label: "Status Pembayaran",
            field: "legality_payment_status",
            thClass: "text-center",
          },
          {
            label: "Fasilitas Customer",
            field: "customer_facility",
            thClass: "text-center",
          },
          {
            label: "Aksi",
            field: "action",
            thClass: "text-center",
            tdClass: "text-center",
            width: "200px",
          },
        ],
        rows: [],
  
        tanggal_filter: "",
  
        configs: {
          mode: "range",
          allowInput: {
            allowInput: true,
            dateFormat: 'Y-m-d'
          },
        },
  
        formSubmit: {
            submission_status: "",
            legality_payment_status: "",
            customer_facility: "",
            description: "",
        },
  
        kavlingOptions: [],
        submissionStatusOptions: [],
        legalPaymentStatusOptions: [],
        customerFacilityOptions: [],
  
        // old delete
        searchTerm: "",
  
        kavling_id: "",
        block_name: "",
        submission_status: "",
        customer_name: "",
        customer_phone: "",
        legality_payment_status: "",
        customer_facility: "",
  
        sort: "",
  
        create_time: "",
  
        show: false,
        aksi: "",
        id_kavling: "",
  
        nama: "",

        id_legal: "",
  
        selected: null,
  
      };
    },
  
    beforeMount() {
      this.getData(this.paging.size, this.paging.page);
      this.getKavling();
      this.getSubmission();
      this.getLegalPayment();
      this.getCustomerFacility();
    },
  
    computed: {
      direction() {
        if (store.state.appConfig.isRTL) {
          this.dir = true;
          return this.dir;
        }
        this.dir = false;
        return this.dir;
      },
    },
  
    created() {
      //here
    },
  
    methods: {
      getData(size, page) {
        this.show = true;
  
        let search1 = "";
        if (this.kavling_id) {
          search1 = "&kavling_id=" + this.kavling_id;
        } else {
          search1;
        }
  
        let search2 = "";
        if (this.block_name) {
          search2 = "&block_name=" + this.block_name;
        } else {
          search2;
        }
  
        let search3 = "";
        if (this.customer_name) {
          search3 = "&customer_name=" + this.customer_name;
        } else {
          search3;
        }
  
        let search4 = "";
        if (this.submission_status) {
          search4 = "&submission_status=" + this.submission_status;
        } else {
          search4;
        }
  
        let search5 = "";
        if (this.legality_payment_status) {
          search5 = "&legality_payment_status=" + this.legality_payment_status;
        } else {
          search5;
        }
  
        return new Promise(() => {
          axios
            .get(
              "v1/legality/all?" +
                "page=" +
                page +
                "&size=" +
                size +
                "&sort_by=create_time&sort_dir=-1" +
                search1 + 
                search2 + 
                search3 + 
                search4 +
                search5
            )
            .then((res) => {
              this.rows = res.data.content;
  
              this.paging.page = res.data.page;
              this.paging.size = res.data.size;
              this.paging.total_elements = res.data.total_elements;
              this.paging.total_pages = res.data.total_pages;
  
              this.show = false;
            })
            .catch((error) => {
              if (error.response.status == 401) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.detail,
                    icon: "XCircleIcon",
                    variant: "danger",
                  },
                });
                this.show = false;
                this.$router.push("/login");
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.detail,
                    icon: "XCircleIcon",
                    variant: "danger",
                  },
                });
                this.show = false;
              }
              this.show = false;
            });
        });
      },
  
      showModalUbah(props) {
        return new Promise(() => {
          axios
            .get(
              "/v1/legality/" + props.row._id
            )
            .then((res) => {
              this.id_legal = res.data._id;
  
              this.formSubmit.submission_status = res.data.submission_status;
              this.formSubmit.legality_payment_status = res.data.legality_payment_status;
              this.formSubmit.customer_facility = res.data.customer_facility;
              this.formSubmit.description = res.data.description;
  
              this.$refs["ref-form-data-legal"].show();
              this.aksi = "ubahData";
  
              this.show = false;
            })
            .catch((error) => {
              if (error.response.status == 401) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.detail,
                    icon: "XCircleIcon",
                    variant: "danger",
                  },
                });
                this.show = false;
                this.$router.push("/login");
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.detail,
                    icon: "XCircleIcon",
                    variant: "danger",
                  },
                });
                this.show = false;
              }
              this.show = false;
            });
        });
      },
  
      kembaliModalForm(){
        this.$refs["ref-form-data-legal"].hide();
        this.show = false;
      },
  
      submitForm(){
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            if (this.aksi === "tambahData") {
              return new Promise(() => {
                axios
                  .post("v1/legality", this.formSubmit)
                  .then((res) => {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Tambah Data Berhasil`,
                        icon: "CheckCircleIcon",
                        variant: "success",
                        text: `Tambah Data Legal Berhasil`,
                      },
                    });
                    this.getData(this.paging.size, this.paging.page);
                    this.$refs["ref-form-data-legal"].hide();
                    this.show = false;
                  })
                  .catch((error) => {
                    if (error.response.status == 401) {
                      this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                          title: error.response.data.detail,
                          icon: "XCircleIcon",
                          variant: "danger",
                        },
                      });
                      this.show = false;
                      this.$router.push("/login");
                    } else {
                      this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                          title: error.response.data.detail,
                          icon: "XCircleIcon",
                          variant: "danger",
                        },
                      });
                      this.show = false;
                    }
                    this.show = false;
                  });
              });
            } else {
              return new Promise(() => {
                axios
                  .put("v1/legality/" + this.id_legal, this.formSubmit)
                  .then((res) => {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Ubah Data Berhasil`,
                        icon: "CheckCircleIcon",
                        variant: "success",
                        text: `Ubah Data Legal Berhasil`,
                      },
                    });
                    this.getData(this.paging.size, this.paging.page);
                    this.$refs["ref-form-data-legal"].hide();
                    this.show = false;
                  })
                  .catch((error) => {
                    if (error.response.status == 401) {
                      this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                          title: error.response.data.detail,
                          icon: "XCircleIcon",
                          variant: "danger",
                        },
                      });
                      this.show = false;
                      this.$router.push("/login");
                    } else {
                      this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                          title: error.response.data.detail,
                          icon: "XCircleIcon",
                          variant: "danger",
                        },
                      });
                      this.show = false;
                    }
                    this.show = false;
                  });
              });
            }
          }
        })
      },
  
      deleteInfo(props) {
        this.$swal({
          title: "Peringatan",
          text: "Menghapus blok akan menghapus data pembelian!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya, Hapus!",
          cancelButtonText: "Batal",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.deleteApi(props);
          }
        });
      },
  
      deleteApi(props) {
        return new Promise(() => {
          axios
            .delete("v1/block/" + props.row._id)
            .then((res) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Hapus Data Berhasil`,
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: `Hapus Data Legal Berhasil`,
                },
              });
              this.getData(this.paging.size, this.paging.page);
            })
            .catch((error) => {
                if (error.response.status == 401) {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: error.response.data.detail,
                      icon: "XCircleIcon",
                      variant: "danger",
                    },
                  });
                  this.show = false;
                  this.$router.push("/login");
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: error.response.data.detail,
                      icon: "XCircleIcon",
                      variant: "danger",
                    },
                  });
                  this.show = false;
                }
                this.show = false;
              });
        });
      },
  
      changeStatus() {
        const page = (this.paging.page = 0);
        this.getData(this.paging.size, page);
      },
  
      getKavling() {
        return new Promise(() => {
          axios.get("v1/kavling/combo").then((res) => {
            this.kavlingOptions = res.data;
          });
        });
      },
  
      getSubmission() {
        return new Promise(() => {
          axios.get("v1/legality/combo/submission_status").then((res) => {
            this.submissionStatusOptions = res.data;
          });
        });
      },
  
      getLegalPayment() {
        return new Promise(() => {
          axios.get("v1/legality/combo/payment_status").then((res) => {
            this.legalPaymentStatusOptions = res.data;
          });
        });
      },
  
      getCustomerFacility() {
        return new Promise(() => {
          axios.get("v1/legality/combo/customer_facility_type").then((res) => {
            this.customerFacilityOptions = res.data;
          });
        });
      },
  
      pilihKavling(event) {
        this.formSubmit.kavling_id = event;
      },
  
      resetFilter(){
        this.block_name = "";
        this.kavling_id = "";
        this.submission_status = "";
        this.customer_name = "";
        this.customer_phone = "";
        this.legality_payment_status = "";
        this.customer_facility = "";

        const page = (this.paging.page = 0);
        this.getData(this.paging.size, page);
      },
  
      handleSearch(searching) {
        const page = (this.paging.page = 0);
        this.getData(this.paging.size, page);
      },
      handleChangePage(value) {
        const page = value - 1;
        this.getData(this.paging.size, page);
      },
      handlePageChange(active) {
        const page = this.paging.page;
        this.getData(this.paging.size, page);
      },
      onSortChange(params) {
        this.sort = `${params[0].field}`;
        this.getData(this.paging.size, this.paging.page);
      },
    },
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-good-table.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  </style>
  